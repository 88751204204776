import React, { useState, useRef } from 'react';
import { useOnClickOutside } from './hooks';
import ScrollToTop from "react-scroll-to-top";


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './styles/main.scss';
import './styles/contactForm.scss';

import Burger from './components/Burger';
import Menu from './components/Menu';
import IntroSlider from './components/IntroSlider';
import introImage from './assets/images/homeSlider/marzano.jpg';
import locationImage from './assets/images/locationSlider/location6.jpg';


function App() {
  const [open, setOpen] = useState(false);

  const node = useRef(); 
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);
  const section5 = useRef(null);
  const section6 = useRef(null);

  useOnClickOutside(node, () => setOpen(false));

  const scrollDown = (ref) => {
    setOpen(false);
    window.scrollTo({
      top: ref.current.offsetTop - 60,
      behavior: 'smooth',
    });
  };

  const scrollToIntro = () => {
    document.getElementById("section2").scrollIntoView();
  };

  const MenuOverlay = () => (
    <div className="menuOverlay"></div>
  );


  return (
    <div className="App">
        <header>
          <div ref={node} className="navigation">
            <Burger open={open} setOpen={setOpen}/>
            <Menu
              open={open}
              setOpen={setOpen}
              scrollDown={scrollDown}
              section1={section1}
              section2={section2}
              section3={section3}
              section4={section4}
              section5={section5}
              section6={section6}
            />
          </div>
        </header>

        <section ref={section1} id="section1" className="section welcome">
          <div className="section__innerWrapper">
            <img src={introImage} className="introImage" alt="" />
            <div className="welcomeCopy">
              <span className="welcomeCopy__date">Saturday, September 14, 2024</span>
              <span className="welcomeCopy__location">Nelson, BC</span>
              <p>We are getting married... again!<br/><br/>Please reply to the email we sent from <strong>m_grant_burns@yahoo.com</strong> to RSVP. </p>
              <div className="arrow bounce" onClick={scrollToIntro}></div>
            </div>
          </div>
          
        </section>

        <section ref={section2} id="section2" className="section intro">
          <div className="section__innerWrapper">
            <div className="section__content__left">
              <IntroSlider className="introImage" />
            </div>
            <div className="section__content__right">
              <h1 className="sectionHeader">Renewing our vows</h1>        
              <p>As all of you know, we got married 5 years ago at the Sochatsky acreage with a very small and intimate ceremony.</p>
              <p>It was a bit of a whilrwind wedding without much planning as we wanted to take advantage of having access to the acreage as well as all of our immediate family members being healthy and able bodied.</p>
              <p>It was always our intention to have another ceremony the following year where we could include all of our dearest friends and loved ones, however that had to be put on hold due to the pandemic.</p>
              <p>Now that that is behind us, we are once again looking to share this super special event in our lives with you!</p>
              <p>Having it in Nelson is important to us as we have built quite a cosey "nest" out here and we would really love to be able to share it with you all... at least once. We understand Nelson is out of the way and it's not a place you end up "passing through" very often.</p>
              {/* <p>We've found a lovely spot at the base of our local ski resort that is perfect and are planning a special time to spend with you.</p> */}
              <p>If you find yourself receiving this invitation, it's becasue we <strong>truly love</strong> having you in our lives and think it would be such a gift to be able to celebrate this experience with you.</p>
              <p>We're casting our net far and wide as some of you are very far away from us now. Some of you we haven't seen in years. This could be a great excuse for us to be together again.</p>
              <p>That being said... if you are unable to attend, we do understand and will look to find another way to connect when the time is right.</p>
            </div>
          </div>
        </section>

        <div id='scrollUp'></div>

        <section ref={section3} id="section3" className="section experience">
          <div className="section__innerWrapper">
            <img src={locationImage} className='locationImage' alt='' />
            <h1 className="sectionHeader">Location - It's a house party!</h1> 
              {/* <p>We have chosen The Hummingbird Lodge as the location for our vow renewals.</p>
              <p>The lodge is at the foot of Whitewater which is a special place for us.</p>
              <p>It's about 20 minutes or so from town and very easy to get to.</p>
              <p>The lodge is brand new this year and has everything we think would make for a memorable event.</p>
              <p>For those who would like to do some exploring there's hiking and tons of nature to take in. As it will be mid September, the larch trees should be starting to transition to their unique glorious golden glow.</p>
              <p>As you'll learn in the Q&A section, there are camping and RV sites available.</p>
              <p>For all information about the location, you can check out the <a href="https://www.hummingbirdlodge.ca/summer/" className='link' target='_blank' rel="noreferrer">Hummingbird Lodge Website</a></p> */}
              <p>Initially we were planning on having the event at the base of Whitewater resort.</p>
              <p>However, after further discussing our needs with them, it became clear that the location wasn't a great fit.</p>
              <p>They have some capacity restrictions due to the type of license they have and it also became clear that they would not be able to accomodate the number of people we anticipate attending in terms of food, etc...</p>
              <p>After taking some time to consider other options, we decided to have it at our place.</p>
              <p>There are still a few things that we are sorting out, like parking... We will add that information here as it becomes available and will also send out emails to notify you when it's available. </p>
          </div>
        </section>


        <section ref={section4} id="section4" className="section cms">
          <div className="section__innerWrapper">
            <h2 className="sectionHeader">Q&A</h2> 
            <p>All your questions answered.</p>
            
            <div className="qa__wrapper">
              <div className="qa__item">
                <h4 className="qa__item__question">RSVP deadline</h4>
                <p>Please RSVP via email to <strong>m_grant_burns@yahoo.com</strong> by July 15th.</p>
              </div>

              <div className="qa__item">
                <h4 className="qa__item__question">Can I bring others?</h4>
                <p>For sure you can bring dates or friends! Just please make sure to include them in your RSVP by July 15th.</p>
              </div>

              <div className="qa__item">
                <h4 className="qa__item__question">Are kids and pets welcome?</h4>
                {/* <p>Both are warmly welcomed! The Hummingbirds rules for dogs at the location is that there is a maximum of 2 per campsite and that they must remain on leash at all time outside of the off-leash area. The site holder is responsible for the behaviour of their pets and for picking up after them. Pets are not to be left unattended.</p>
                <p>The rules in their entirety can be found <a href="https://www.hummingbirdlodge.ca/summer/about/policies/" className='link' target="_blank" rel="noreferrer">here</a> under pets.</p> */}
                <p>Kids are more than welcome! As far as dogs are concerned, it will depend on how many? There are going to be around 100 people in attendance and the house will feel pretty full. If a couple of dogs are looking to come along, it should be fine. If there are more than 4-5 it might be a bit much. If you would like to RSVP your dog, please let us know and we will try our best to accomodate everyone!</p>
              </div>

              <div className="qa__item">
                <h4 className="qa__item__question">Accommodations</h4>
                <p><strong className='marginB'>Camping | RV</strong><br/>
                If you are looking to camp or RV, <a href="https://www.campspot.com/book/hummingbirdlodge" className='link' target="_blank" rel="noreferrer">The Hummingbird Lodge</a> is stil an option. It's about a 15 - 20 min drive up Highway 6 from our place. There is also the <a href="https://letscamp.ca/camps/nelson-city-campground" className='link' target="_blank" rel="noreferrer">Nelson City Campground</a> right in the heart of town which is open until October 16th (just FYI).</p>
                <p><strong className='marginB'>Hotels</strong><br/>
                There are many awesome hotels in Nelson! A great "cheaper" option is <a href="https://www.alpine-motel.com/" className='link' target="_blank" rel="noreferrer">The Alpine Motel</a>. Friends and family often stay there while visiting as it's very close by. It's clean and the staff are super friendly!</p>
                
                <p>Other options include <a href="https://www.humehotel.com/" className='link' target="_blank" rel="noreferrer">The Hume</a>, <a href="https://www.savoyhotel.ca/" className='link' target="_blank" rel="noreferrer">The Savoy</a> and  <a href="https://www.prestigehotelsandresorts.com/locations/nelson-lakeside-resort/overview/" className='link' target="_blank" rel="noreferrer">The Prestige</a> just to name a few.</p>
                <p><strong className='marginB'>Airbnb</strong><br/>
                <a href="https://www.airbnb.ca/s/nelson-BC/homes?query=nelson+BC" className='link' target="_blank" rel="noreferrer">Airbnb</a> is obviously also an option and there are some really cool places to stay in the area.</p>
              </div>

              <div className="qa__item">
                <h4 className="qa__item__question">Attire</h4>
                <p>The category is... <strong>Mountain Chic Realness.</strong></p>
                <p>This will be September in the mountains so the weather will be something to consider. It could be that the days are nice and warm but the evenings will definitely be cooler. It could be that the days are cooler as well so it's best to pack all your favourite sweaters and fall accessories.</p>
                <p>Underlayers are a good thing to have on hand.</p>
              </div>

              <div className="qa__item">
                <h4 className="qa__item__question">Ceremony & Reception</h4>
                <p>We are planning to have the ceremony outdoors at 3:00pm. There will be nibbles, nice beverages and some music to carry the vibe.</p>
                <p>At around 5:30pm we will have a food truck serving us at the front of the house for dinner.</p>
                <p>Around 9:00pm, our basement suite will become a discoteque with Jay (and perhaps a guest or two) dj'ing. At this point it becomes a dance party and we're really looking forward to it!</p>
              </div>

              <div className="qa__item">
                <h4 className="qa__item__question">Who to contact with questions?</h4>
                <p>You are welcome to either send questions to the RSVP email address, <strong>m_grant_burns@yahoo.com</strong> or call Jay @ <a href="tel:6047162373" className='link'>(604) 716-2373</a> or Grant @ <a href="tel:6047794651" className='link'>(604) 779-4651</a></p>
              </div>

              <div className="qa__item">
                <h4 className="qa__item__question">Gifts?</h4>
                <p>Your presence is the only gift we could ask for. Seriously, if you actually make it, we will consider it a very special gift.</p>
              </div>
            </div>

          </div>
        </section>



        
        <section ref={section5} id="section5" className="section schedule">
          <div className="section__innerWrapper">
            <div className='centered'>
              <h1 className="sectionHeader">Weekend Schedule</h1> 
              <p><strong className='marginB'>Friday - Sept 13th</strong></p>
              <p>We are going to be having a chill low-key diner at our place on the Friday night with Family. If you are around and are interested in stopping in to say hello, please feel free! </p>
              <br/>

              <p><strong className='marginB'>Saturday - Sept 14th</strong></p>
              <p>We will be having a short ceremony at 3:00pm in our backyard followed by schmoozing with nibbles and drinks.</p>
              <p>At 5:30pm the Kickin'Ash BBQ food truck will be stationed at the front of the house providing us with dinner. They do great traditional BBQ while also accomodating vegetarians!</p>
              <p>There will be a few speeches and things after dinner.</p>
              <p>Starting around 9:00pm, our basement suite will become the dance floor center of the house party that will ensue. The upper floors will be there for those who would rather socialize while those who are looking to dance can tear it up downstairs.</p>

            </div>
          </div>
        </section>


        <section ref={section6} id="section6" className="section contact">
          <div className="section__innerWrapper">
            <h1 className="sectionHeader">Getting To Nelson</h1> 
            <div className="section__content__left">
              <h4 className="sectionSubHeader">Travel information</h4>
              <p>Nelson is a 7.5 hour drive from Vancouver and about 8.5 - 9 hours from Edmonton. Either can be done in a single day, if you leave super early, you arrive mid-late afternoon and there's still time for dinner! 🍕</p>
              <p>If you have the luxury of time, splitting the drive up over a couple of days is a great way to stop and smell the roses so to speak. There's many potential overnight options if that's your vibe.</p>
              <p>Flying into Castlegar is also an option, however a less reliable one. It's called Cancel-gar for a reason... If you fly out a day or two early, there's less of a chance of your plans being completely disrupted.</p>
              <p>It's a 25 min drive to Nelson from Castlegar, there are not many (if any) shuttle options to Nelson unfortunately :(</p>
              <p>The complete address is 2434B Perrier Lane, Nelson BC.</p>
            </div>
            <div className="section__content__right">
              <div>
                {/* <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10376.089016182605!2d-117.1479907!3d49.4462964!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x537cb37916e7f7d3%3A0xf6fc27bb7aeb5f43!2sHummingbird%20Lodge%20%26%20Campground!5e0!3m2!1sen!2sca!4v1709253452703!5m2!1sen!2sca" width="100%" height="300" style={{border: '0'}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe> */}
                <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2592.612955554816!2d-117.29443422299231!3d49.47292747142209!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x537cb43d825d49f3%3A0x5c9044ae378dd0a5!2s2434%20Perrier%20Ln%2C%20Nelson%2C%20BC%20V1L%206Y9!5e0!3m2!1sen!2sca!4v1715213006731!5m2!1sen!2sca" width="100%" height="300" style={{border: '0'}} allowfullscreen="true" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
          </div>
        </section>

        <ScrollToTop smooth top={500} />

        {open && <MenuOverlay />}
        
    </div>
  );
}

export default App;
