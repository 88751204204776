// Menu.js
import React from 'react';
import { Link } from "react-scroll";

const Menu = ({ open, scrollDown, section1, section2, section3, section4, section5, section6, section7, section8 }) => {
  return (
  <nav open={open} className={`menu ${open ? " is-visible" : ""}`}>
    <ul className="menuList">
    <li className="menuList__link" >
        <Link
          activeClass="active"
          smooth
          offset={-60}
          duration={0}
          delay={0}
          spy
          to="section1"
          onClick={() => scrollDown(section1)}
        >
          RSVP
        </Link>
      </li>
      <li className="menuList__link" >
        <Link 
          activeClass="active"
          smooth
          offset={-60}
          duration={0}
          delay={0}
          spy
          to="section2"
          onClick={() => scrollDown(section2)}
        >
          Renewing our vows
        </Link>
      </li>
      <li className="menuList__link" >
        <Link
          activeClass="active"
          smooth
          offset={-60}
          duration={0}
          delay={0}
          spy
          to="section3"
          onClick={() => scrollDown(section3)}
        >
          Location
        </Link>
      </li>
      <li className="menuList__link" >
        <Link
          activeClass="active"
          smooth
          offset={-60}
          duration={0}
          delay={0}
          spy
          to="section4"
          onClick={() => scrollDown(section4)}
        >
          Q&A
        </Link>
      </li>
      <li className="menuList__link" >
        <Link
          activeClass="active"
          smooth
          offset={-60}
          duration={0}
          delay={0}
          spy
          to="section5"
          onClick={() => scrollDown(section5)}
        >
          Weekend Schedule
        </Link>
      </li>
      <li className="menuList__link" >
        <Link
          activeClass="active"
          smooth
          offset={-60}
          duration={0}
          delay={0}
          spy
          to="section6"
          onClick={() => scrollDown(section6)}
        >
          Getting To Nelson
        </Link>
      </li>
      {/* <li className="menuList__link" >
        <Link
          activeClass="active"
          smooth
          // offset={-80}
          duration={0}
          delay={0}
          spy
          to="section8"
          onClick={() => scrollDown(section8)}
        >
          Contact
        </Link>
      </li> */}
    </ul>
  </nav>
  )
}
export default Menu;