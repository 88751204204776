import React from "react";
import Slider from "react-slick";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs"

export default function IntroSlider({ scrollDown, section2, section3 }) {

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "leftArrow" +
        (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <BsChevronLeft />
    </button>
  );

  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "rightArrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    >
      <BsChevronRight />
    </button>
  );
  
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    fade: true,
    cssEase: 'linear',
    nextArrow: <SlickArrowRight />,
    prevArrow: <SlickArrowLeft />,
  };

  return (
    <Slider {...settings} className={'introSlider'}>
      <div className={'slide slide1'}></div>
      <div className={'slide slide2'}></div>
      <div className={'slide slide3'}></div>
      <div className={'slide slide4'}></div>
      <div className={'slide slide5'}></div>
      <div className={'slide slide6'}></div>
      <div className={'slide slide7'}></div>
      <div className={'slide slide8'}></div>
      <div className={'slide slide9'}></div>
      <div className={'slide slide10'}></div>
      <div className={'slide slide11'}></div>
      <div className={'slide slide12'}></div>
      <div className={'slide slide13'}></div>
    </Slider>
  );
}